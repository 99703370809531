//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import Avatar from '@/components/Avatar.vue'
import Reporter from '@/components/Reporter/Reporter'

export default {
  name: "EventNamestrip",
  components: { Avatar, Reporter },
  props: [
    'event',
    'privacy',
    'isMember',
    'membersCount',
    'isAdmin',
    'isOwner'
  ],
  data(){
    return {
      event_host: '',
      isSharing: false,
      showReporter: false
    }
  },
  methods:{
    shareEvent() {
      this.$q.dialog({
        title: 'Share ' + this.event.name + ' to your wall?',
        message: 'When sharing this event, it will be posted on your wall and your friends will get to see it. <br><br>Continue?' +
        '<br><br><div class="text-caption text-grey-7">The events that are set as private will not be visible from other players if they are not invited by the owner or do not have an active invite.</div>',
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        this.isSharing = true;
        this.$http({
          url: 'posts/share_entity',
          method: 'POST',
          data: { entity_id: this.event.id }
        }).then((response) => {
          this.isSharing = false;
          switch (response.data['status']) {
            case 'share_interval':
              this.$q.notify({
                message: 'Please wait for a while when sharing the same Event.',
                color: 'primary'
              });
            break;
            case 'share_successful':
              this.$q.notify({
                message: 'This event was successfully shared on your wall and can now be seen by your friends.',
                color: 'blue'
              });
            break;
          }
        }).catch((err) => {
          this.isSharing = false;
          switch (err.response.status) {
            case 403:
              this.$q.notify({
                message: 'You do not have the permission to share this event.',
                color: 'warning'
              });
            break;
            case 404:
              this.$q.notify({
                message: 'The event that you are trying to share was not found.',
                color: 'negative'
              });              
            break;
            case 500:
              this.$q.notify({
                message: 'An error has occurred while trying to share. Please try again later.',
                color: 'negative'
              });   
            break;
          }
        })
      }).onCancel(() => {
        //
      }).onDismiss(() => {
        //
      })
    },
    updateEvent(id){
      this.$router.push({name: 'EventSettings', params: {id:id}})
    },
    leave(){
      this.$q.dialog({
        title: 'Leave Event?',
        message: 'Are you sure you want to leave this event?',
        cancel: true,
      }).onOk(() => {
        this.$http({
        url:'/events/'+ this.event.id +'/leave',
        method:'POST',
      }).then((response) =>{
        if(response.data['status'] == 'event_left') {
          this.$q.notify({
            message: 'You are no longer part of this event',
            color: 'primary',
            icon: 'feedback'
          });
          setTimeout(() => {
            location.reload();
          }, 2000);

          return;
        }
        if(response.data['status'] == 'not_a_member'){
          this.$q.notify({
            message:"You are not a member of this event",
            color: 'negative',
            icon: 'error'
          })
        }else{
          this.$q.notify({
            message:"You are not a member of this event",
            color: 'negative',
            icon: 'error'
          })
        }
      })
      }).onCancel(() => {
      }).onDismiss(() => {
        this.isSharing = false;
      })
      
    },

    deleteEvent(id){
            this.$q.dialog({
                title:'Delete Event',
                message: 'Are you sure you want to delete this event?',
                cancel: true,
            })
            .onOk(() => {
                this.$store.dispatch('event/deleteEvent',id)
                .then((r) =>{
                    if(r == 'success'){
                        this.$q.notify({
                            message:'The event has been deleted.',
                            color:'primary',
                            icon: 'event_busy'
                        })
                        this.$router.push('/events')
                    }
                })
            })
            .onCancel(() =>{})
            .onDismiss(() => {})
        },

    loadUser(id){
            this.$store.dispatch('profile/getUser',id)
            .then((r) => {
                if(r.type == 'success'){
                    this.event_host = r.name
                }
            })
        },
  },
  filters: {
        filterEventDate: function (date,date_end) {
           return date_end == date ? moment(new Date(date)).format('MMMM DD, YYYY') :   moment(new Date(date)).format('MMMM DD, YYYY') + ' - ' + moment(new Date(date_end)).format('MMMM DD, YYYY')
        },
    },
    created(){
      this.loadUser(this.event.user_id)
    }
}
